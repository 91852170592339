'use client';
import React from 'react';
import { getRegionByVoteId } from '@mentimeter/region';
import { useRouter } from 'next/navigation';
import { MentimeterLogoType } from '@mentimeter/ragnar-visuals';
import { useHasMounted } from '@mentimeter/react-hooks';
import {
  isAxiosError,
  setClientFallbackRegion,
} from '@mentimeter/http-clients';
import type { JSX } from 'react';
import type { TextInputItemT } from '@mentimeter/ragnar-ui/input/text-input';
import { TextInputItem } from '@mentimeter/ragnar-ui/input/text-input';
import { Box } from '@mentimeter/ragnar-ui/box';
import { Button } from '@mentimeter/ragnar-ui/button';
import { ErrorMessage } from '@mentimeter/ragnar-ui/error-message';
import { Form } from '@mentimeter/ragnar-ui/form';
import { Link } from '@mentimeter/ragnar-ui/link';
import { Text } from '@mentimeter/ragnar-ui/text';
import { identifierClient } from '../utils/identifier';
import { Footer, PageLayout } from '../ui';
import { trackEvent, useTrack } from '../utils/tracker';
import { useLayoutEffect } from '../utils/isomorphicLayoutEffect';
import { getSeriesById } from '../api/series';
import { useSplitTrack } from '../split.io';
import { CookieBanner } from '../features/cookie-consent/CookieBanner';
import { useCookieConsent } from '../features/cookie-consent/CookieConsent';
import { useTranslate } from '../features/localization/Translate';

const UTM_URL_PARAMS =
  'utm_campaign=first%20page&utm_medium=web-link&utm_source=govote&utm_term=';

const NetworkError = (
  <Box width="100%">
    <Text textAlign="center" color="negative">
      There was a problem loading the presentation. Please check your Internet
      connection and try again.
    </Text>
    <Text textAlign="center" color="negative">
      If the problem persists, your network might block our servers. Please
      refer your local IT support to our{' '}
      <Link
        href="https://www.mentimeter.com/requirements"
        target="_blank"
        rel="noopener noreferrer"
      >
        network requirements
      </Link>{' '}
      page or contact hello@mentimeter.com
    </Text>
  </Box>
);

const strip = (value: string): string => value.replace(/\s/g, '');
const format = (value: string): string =>
  strip(value)
    .replace(/(.{4})/g, '$1 ')
    .trim();

const getNextCursorPosition = (
  currentPos: number,
  oldValue: string,
  newValue: string,
) => {
  if (oldValue.length === currentPos) {
    // Cursor is at the end of the value, so we keep it there e.g. "12632|*" -> "1263 2|*".
    return newValue.length;
  }

  const oldRawValue = strip(oldValue.slice(0, currentPos));
  const newRawValue = strip(newValue.slice(0, currentPos));
  const lengthOffset = oldRawValue.length - newRawValue.length;

  // Offset is either -1 if character(s) were removed or +1 if character(s) were added.
  // 0 when no change in number of characters.
  // Stripped values and before current cursor position.
  const positionOffset =
    lengthOffset !== 0 ? lengthOffset / Math.abs(lengthOffset) : 0;

  return currentPos + positionOffset;
};

function useRunAfterUpdate() {
  // https://egghead.io/lessons/react-preserve-cursor-position-when-filtering-out-characters-from-a-react-input
  const afterPaintRef = React.useRef<(() => void) | null>(null);
  useLayoutEffect(() => {
    if (afterPaintRef.current) {
      afterPaintRef.current();
      afterPaintRef.current = null;
    }
  });
  const runAfterUpdate = (fn: () => void) => (afterPaintRef.current = fn);
  return runAfterUpdate;
}

interface VoteIdInputProps extends TextInputItemT {
  setValue: React.Dispatch<React.SetStateAction<string>>;
}

const VoteIdInput = ({
  value,
  setValue,
  onChange,
  ...rest
}: VoteIdInputProps) => {
  const [valueChanged, setValueChanged] = React.useState<number>(0);
  const runAfterUpdate = useRunAfterUpdate();
  const handleVoteIdChange: React.ChangeEventHandler<HTMLInputElement> = (
    evt,
  ) => {
    const input: HTMLInputElement = evt.target;
    const VoteId = input.value;
    const cursor = input.selectionStart;
    // on non-text input elements, this could be null. more info: https://html.spec.whatwg.org/multipage/form-control-infrastructure.html#dom-textarea/input-selectionstart
    if (cursor !== null) {
      const formattedVoteId = format(VoteId);
      const newCursor = getNextCursorPosition(cursor, VoteId, formattedVoteId);

      if (value === formattedVoteId) {
        // Fix for when a user tries to add a space or remove a space value.
        // Trigger a re-render since value will not update, but we still need to re-position the cursor.
        setValueChanged(valueChanged + 1);
      }

      setValue(formattedVoteId);

      runAfterUpdate(() => {
        input.selectionStart = newCursor;
        input.selectionEnd = newCursor;
      });
    }
  };

  const ref = React.useRef<HTMLInputElement>(null);
  React.useEffect(() => {
    // We don't want to autofocus the vote code input if voting-web is in a frame
    if (window?.location.search.includes('focus=false')) return;

    ref.current?.focus();
  }, []);

  return (
    <TextInputItem
      ref={ref}
      value={value}
      onChange={(e) => {
        handleVoteIdChange(e);
        onChange?.(e);
      }}
      {...rest}
    />
  );
};

type State = 'stale' | 'fetching' | 'invalid_input' | 'not_found' | 'error';

const Home = () => {
  const [voteIdInput, setVoteIdInput] = React.useState<string>('');
  const [invalidValue, setInvalidValue] = React.useState<string>('');
  const [fetchState, setFetchState] = React.useState<State>('stale');
  const [showErrorState, setShowErrorState] = React.useState<boolean>(false);
  const { acceptCookies } = useCookieConsent();

  const router = useRouter();
  const trackSplit = useSplitTrack();
  const trackVoter = useTrack();

  const translate = useTranslate();

  // If a user submits the form before javascript has loaded, the page will
  // reload and the form will be cleared. This is confusing, and not uncommon
  // for users with a slow internet connection.
  //
  // Disabling the form initially and then re-enabling it in an effect
  // ensure that users don't end up in this situation.
  const [formDisabled, setFormDisabled] = React.useState(true);
  React.useEffect(() => {
    setFormDisabled(false);
  }, []);

  async function onSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();

    if (!voteIdInput) {
      setFetchState('invalid_input');
      setShowErrorState(true);
    } else {
      setFetchState('fetching');
      const region = getRegionByVoteId(voteIdInput);

      try {
        setClientFallbackRegion('voting', region);
        identifierClient.setRegion(region);

        const { data } = await getSeriesById(voteIdInput);
        if (!data) throw new Error('Invalid json response from core.');

        trackEvent('home', 'Submitted presentation code', 'success');
        trackVoter(
          {
            event: 'Submitted code',
            properties: {
              status: 'success',
            },
          },
          region,
        );
        trackSplit('Submitted_code');
        acceptCookies(); // We interpret the user joining a presentation as consent

        // We ignore the actual data and push the user to the series page where it will be reused.
        // TODO: Get rid of the extra request. Either use a network cache and shallow routing,
        // or make this request only fetch the vote key and nothing else.
        const pathname =
          data.pace?.mode === 'audience'
            ? `/${data.vote_key}/0`
            : `/${data.vote_key}`;
        const toBePushed = `${pathname}?source=voteCode`;
        router.push(toBePushed);
      } catch (err) {
        if (isAxiosError(err)) {
          const status = err.response?.status;
          const data = err.response?.data as undefined | Record<string, any>;

          if (status === 403 && data?.vote_key) {
            trackEvent('home', 'Submitted presentation code', 'closed');
            trackVoter(
              {
                event: 'Submitted code',
                properties: {
                  status: 'closed',
                },
              },
              region,
            );
            router.push(`/${data?.vote_key}`);
            return;
          }

          if (status === 404) {
            trackEvent('home', 'Submitted presentation code', 'fail');
            trackVoter(
              {
                event: 'Submitted code',
                properties: {
                  status: 'fail',
                },
              },
              region,
            );
            setInvalidValue(voteIdInput);
            setFetchState('not_found');
            setShowErrorState(true);
            return;
          }
        }

        // Any other errors are tracked
        trackEvent('home', 'Submitted presentation code', 'fail');
        trackVoter(
          {
            event: 'Submitted code',
            properties: {
              status: 'fail',
            },
          },
          region,
        );
        setFetchState('error');
        setShowErrorState(true);
      }
    }
  }

  let errorMessage: JSX.Element | string = '';
  if (fetchState === 'invalid_input')
    errorMessage = 'You must enter a code to continue.';
  else if (fetchState === 'not_found')
    errorMessage = `Code ${invalidValue} could not be found. Please try again.`;
  else if (fetchState === 'error') errorMessage = NetworkError;

  const hasMounted = useHasMounted();

  return (
    <PageLayout
      footer={<Footer context="Home" utmUrlParams={UTM_URL_PARAMS} />}
    >
      <Box
        height="100%"
        width="100%"
        alignItems="center"
        justifyContent={['center', 'flex-start']}
        // safari workaround
        m={['auto', 0]}
        maxWidth="320px"
      >
        <Box
          width="100%"
          maxHeight="20px"
          height="100%"
          mt="space20"
          mb="space10"
        >
          <MentimeterLogoType height="100%" />
        </Box>

        <Box width="100%" alignItems="center" gap="space2" mb="space6">
          <Text
            as="h1"
            color="text"
            fontSize="175"
            textAlign="center"
            fontWeight="regular"
          >
            {translate('buttons.enter_code')}
          </Text>
          <Text textAlign="center" color="textWeaker">
            {translate('messages.see_code')}
          </Text>
        </Box>

        <Form width="100%" alignItems="center" onSubmit={onSubmit}>
          <Box width="100%" mb="space6">
            <VoteIdInput
              autoComplete="off"
              aria-label={translate('buttons.enter_digit_code')}
              type="tel"
              value={voteIdInput}
              name="id"
              data-testid="vote-code"
              placeholder="1234 5678"
              status={showErrorState && errorMessage ? 'error' : undefined}
              setValue={setVoteIdInput}
              // You can enter a max of 12 chars ("12 34 56 78")
              maxLength={12}
              onChange={() => {
                setShowErrorState(false);
              }}
              aria-describedby={
                showErrorState && errorMessage ? 'error-msg' : undefined
              }
            />
            {showErrorState && errorMessage && (
              <ErrorMessage id="error-msg" mt="space2" mb="space0">
                {errorMessage}
              </ErrorMessage>
            )}
          </Box>
          <Button
            variant="primary"
            size="large"
            type="submit"
            state={
              fetchState === 'fetching' || formDisabled ? 'loading' : undefined
            }
            mt={3}
            // Prevent multiple submissions as it could lead to
            // having the wrong region for different requests
            disabled={formDisabled || fetchState === 'fetching'}
          >
            {translate('home_view.join')}
          </Button>
        </Form>

        {hasMounted && (
          <Box maxWidth={400} bottom="0" mt="space4">
            <CookieBanner />
          </Box>
        )}
      </Box>
    </PageLayout>
  );
};

export default Home;
