import { voting } from '@mentimeter/http-clients';
import type { ReactionPayload, UpvotePayload } from '@mentimeter/http-clients';
import { getRegionByVoteKey } from '@mentimeter/region';
import type {
  InteractiveResponse,
  Vote,
} from '@mentimeter/question-modules-types';
import type { InteractiveContent } from '@mentimeter/voting-schema/api-types-overrides';

export const getSeriesById = (id: string) => {
  if (!id || !id.replace) {
    return Promise.reject(new Error('Invalid id given'));
  }
  const voteId = id.replace(/[^\d]/g, '');
  return voting().series.getById(voteId);
};

export function getSeriesByKey(key: string) {
  const region = getRegionByVoteKey(key);

  return voting({ region }).series.getByKey(key);
}

export function getSeriesIdByKey(key: string) {
  const region = getRegionByVoteKey(key);

  return voting({ region }).series.getSeriesIdByKey(key);
}

export function postVote(questionPublicKey: string, payload: Vote) {
  return voting().vote.post(questionPublicKey, payload);
}

export function postVoteV2(
  interactiveContentId: InteractiveContent['interactiveContentId'],
  response: InteractiveResponse,
) {
  return voting().voteV2.post(interactiveContentId, response);
}

export function postUpvote({
  voteKey,
  questionPublicKey,
  payload,
  voteId,
}: {
  voteKey: string;
  questionPublicKey: string;
  voteId: string;
  payload: UpvotePayload;
}) {
  return voting().vote.upvote(voteKey, questionPublicKey, voteId, payload);
}

export function publishReaction(
  questionPublicKey: string,
  voteKey: string,
  payload: ReactionPayload,
) {
  return voting().audience.reactions.publish(
    questionPublicKey,
    voteKey,
    payload,
  );
}

export function postReaction(
  questionPublicKey: string,
  voteKey: string,
  payload: ReactionPayload,
) {
  return voting().audience.reactions.post(questionPublicKey, voteKey, payload);
}

export const fetchGroupedResults = async (questionPublicKey: string) => {
  return voting().groups.get(questionPublicKey);
};
